<template>

  <div>
    <section class="ftco-section bg-light">
      <div class="container">
        <div class="row justify-content-center mb-5 pb-3">
          <div class="col-md-7 text-center heading-section ">
            <h2>Blog</h2>
            <div class="">
              <form action="#" class="search-form">
                <div class="form-group">
                  <div class="icon">
                    <a href="" @click.prevent="carregaBusca()" class="icon-search"></a>
                  </div>
                  <input v-model="inputBusca" type="text" class="form-control" placeholder="Digite palavra-chave">
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row">
          <div v-for="blog in BlogsFiltro" :key="blog.institucionalId" class="col-md-4 ">
            <div class="blog-entry">
              <router-link :to="/blogDetalhe/ + blog.institucionalId + '/' + blog.nome.replaceAll(' ','-').replaceAll('/','')" class="block-20"
                :style="{ backgroundImage: 'url(' + $apiUrlIndex + blog.institucionalImg[0].urlImagem + ')' }">
              </router-link>
              <div class="text d-flex py-4">
                <div class="desc pl-3">
                  <div>
                    <router-link :to="/blogDetalhe/ + blog.institucionalId + '/' + blog.nome.replaceAll(' ','-').replaceAll('/','')">{{ currentDateTime(blog.data1) }}</router-link>
                  </div>
                  <h3 class="heading">
                    <router-link :to="/blogDetalhe/ + blog.institucionalId + '/' + blog.nome.replaceAll(' ','-').replaceAll('/','')"> {{ blog.nome }}</router-link>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--         <div class="row mt-5">
          <div class="col text-center">
            <div class="block-27">
              <ul>
                <li><a href="#">&lt;</a></li>
                <li class="active"><span>1</span></li>
                <li><a href="#">2</a></li>
                <li><a href="#">3</a></li>
                <li><a href="#">4</a></li>
                <li><a href="#">5</a></li>
                <li><a href="#">&gt;</a></li>
              </ul>
            </div>
          </div>
        </div> -->
      </div>
    </section>

  </div>
</template>

<script>
import moment from 'moment'
export default {
  components: {
  },
  data() {
    return {
      Blogs: [],
      BlogsFiltro:[],
      inputBusca:'',
    }
  },
  created() {

 if (this.inputBusca==''){
    this.$http
      .get(this.$apiUrl + "/institucional/telas/notícias")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.Blogs = retornoAPI.filter((x) => x.visivel)

        this.Blogs.sort(function (a, b) {
          if (a.data1 > b.data1) { return 1; }
          if (a.data1 < b.data1) { return -1; }
          return 0;
        }).reverse();
        this.BlogsFiltro = this.Blogs;
      });
 }

  },
  methods: {
    currentDateTime(vdate) {
      return moment(vdate).format('DD/MM/YYYY')
    },
    carregaBusca(){
      if (this.inputBusca=='' || this.inputBusca==null){
        this.BlogsFiltro = this.Blogs;
      }
      else{
        let _texto = this.inputBusca.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        this.BlogsFiltro = this.Blogs.filter(x => (x.nome != null &&  
                                             x.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(_texto.toLowerCase()) != -1)
                                         || (x.descricao1 != null  && x.descricao1.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().indexOf(_texto.toLowerCase()) != -1))
      }
    }
  }
}

</script>

<style>
</style>