<template>
    <section class="ftco-partner">
      <div class="container">
        <div class="row justify-content-center pb-3">
          <div class="col-md-7 text-center heading-section ">
            <h2 class="mb-4">{{Titulo}}</h2>
            <p v-html="Texto"></p>
          </div>
        </div>
        <div class="row" style="justify-content: center;">
          <div v-for="item in parceiroslogos" :key="item.institucionalId" class="col-sm-2 col-4 mt-4">
            <a class="partner"><img :src="$apiUrlIndex + item.institucionalImg[0].urlImagem" class="img-fluid" style="width: 100%;" :alt="item.nome"></a>
          </div>
        </div>
      </div>
    </section>
</template>

<script>

export default {
  data() {
    return {  
      parceiroslogos:[],
      TituloParceiros:[],
      Titulo:'',
      Texto:'',
      };
  },
  created() {
     this.$http
      .get(this.$apiUrl + "/institucional/telas/parceirostítulo")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.TituloParceiros = retornoAPI
        if (this.TituloParceiros!=''){
          this.Titulo = this.TituloParceiros[0].nome;
          this.Texto = this.TituloParceiros[0].descricao1;
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/parceiroslogos")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.parceiroslogos = retornoAPI.filter((x) => x.visivel)

        this.parceiroslogos.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })
      });
  },
  methods: {
  },
};
</script>