<template>
  
   <section  v-if="numeros!= '' && numeros!=null" class="ftco-section ftco-counter img" id="section-counter">
      <div class="container">
        <div class="row justify-content-center mb-3">
          <div class="col-md-7 text-center heading-section heading-section-white ">
            <span class="subheading">PORQUE ESCOLHER A STATUS</span>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="row" style="justify-content: center;">
              <div v-for="num in numeros" :key="num.institucionalId" class="col-md-3 d-flex justify-content-center counter-wrap ">
                <div class="block-18 text-center">
                  <div class="text">
                    <strong class="number"><VueJsCounter start="0" :end="num.referencia" duration="8000"></VueJsCounter></strong>
                    <span>{{ num.nome }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

</template>


<script>
import VueJsCounter from 'vue-js-counter'
export default {
  components: {
    VueJsCounter,
        },
  data() {
    return {
      numeros: [],
    }
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/números")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.numeros = retornoAPI.filter((x) => x.visivel)

        this.numeros.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })
      });
  }
}
</script>

<style>

</style>