<template>

  <footer class="ftco-footer ftco-bg-dark ftco-section">
    <div class="container">
      <div class="row mb-5">
        <div class="col-md-3">
          <div class="ftco-footer-widget mb-4 bg-primary p-4">
            <h2 class="ftco-heading-2"><img src="/images/logoRodape.png" class="img-fluid" style="width: 180px;" /></h2>
            <p v-html="sobreRodape"></p>
            <ul class="ftco-footer-social list-unstyled mb-0">
              <li class=""><a v-if="facebookLink != ''" :href="facebookLink" target="_blank"><span
                    class="icon-linkedin"></span></a></li>
              <li class=""><a v-if="linkedinLink != ''" :href="linkedinLink" target="_blank"><span
                    class="icon-facebook"></span></a></li>
              <li class=""><a v-if="instagramLink != ''" :href="instagramLink" target="_blank"><span
                    class="icon-instagram"></span></a></li>
            </ul>
          </div>
        </div>
        <div class="col-md-4">
          <div class="ftco-footer-widget mb-4 ml-md-5">
            <h2 class="ftco-heading-2">Soluções</h2>
            <ul class="list-unstyled">
              <li v-for="seg in SegmentosFiltro" :key="seg.segmentoInstNivel1Id">
                 <a href="#" @click="$redirect_reload('/solucoes/' + seg.segmentoInstNivel1Id + '/' + seg.nomeSegmento.replaceAll(' ','-').replaceAll('/',''))" class="py-2 d-block">{{seg.nomeSegmento}}</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-2">
          <div class="ftco-footer-widget mb-4">
            <h2 class="ftco-heading-2">Site</h2>
            <ul class="list-unstyled">
              <li>
                <router-link to="/" class="py-2 d-block">Home</router-link>
              </li>
              <li>
                <router-link to="/sobre" class="py-2 d-block">Empresa</router-link>
              </li>
              <!-- <li>
                <router-link to="/blog" class="py-2 d-block">Blog</router-link>
              </li> -->
              <li>
                <router-link to="/contato" class="py-2 d-block">Contato</router-link>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-3">
          <div class="ftco-footer-widget mb-4">
            <h2 class="ftco-heading-2">Contatos</h2>
            <div class="block-23 mb-3">
              <ul>
                <li v-if="Endereco != '' && Endereco != null"><a><span class="icon icon-map-marker"></span>
                    <span class="text" v-html="Endereco"></span></a></li>
                <li v-if="Fone != '' && Fone != null"><a :href="Link"><span class="icon icon-phone"></span><span
                      class="text">{{ Fone }}</span></a></li>
                <li v-if="Email != '' && Email != null">
                  <router-link to="/contato"><span class="icon icon-envelope"></span><span class="text">{{ Email
                  }}</span></router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 text-center">
          <a href="https://avancedigital.com.br/" target="_blank">
            <img class="logo_avance" src="/images/favicon.png" style="opacity: 0.4;">
          </a>
        </div>
      </div>
    </div>
  </footer>

</template>


<script>
export default {
  data() {
    return {
      infoRodape: [],
      sobreRodape: '',
      Endereco: '',
      Fone: '',
      Email: '',
      Link: '',
      socialFacebook: [],
      facebookLink: '',
      socialInstagram: [],
      instagramLink: '',
      socialLinkedin: [],
      linkedinLink: '',
      loginLink: [],
      InfLgpd: [],
      Segmentos:[],
      SegmentosFiltro:[],
    }
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/info%20rodape")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.infoRodape = retornoAPI
        if (this.infoRodape != '') {
          this.sobreRodape = this.infoRodape[0].resumo1
          this.Endereco = this.infoRodape[0].resumo2
          this.Fone = this.infoRodape[0].nome
          this.Email = this.infoRodape[0].campoExtra1
          this.Link = this.infoRodape[0].referencia
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/facebook")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.socialFacebook = retornoAPI.filter((x) => x.visivel)
        if (this.socialFacebook != '') {
          this.facebookLink = this.socialFacebook[0].referencia
        }


      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/instagram")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.socialInstagram = retornoAPI.filter((x) => x.visivel)
        if (this.socialInstagram != '') {
          this.instagramLink = this.socialInstagram[0].referencia
        }
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/linkedin")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.socialLinkedin = retornoAPI.filter((x) => x.visivel)
        if (this.socialLinkedin != '') {
          this.linkedinLink = this.socialLinkedin[0].referencia
        }
      });

     this.$http
       .get(this.$apiUrl + "/segmentoinstnivel1")
       .then((res) => res.json())
       .then((retornoAPI) => {
         this.Segmentos = retornoAPI.filter((x) => x.visivel)

        this.Segmentos.sort(function (a, b) {
           if (a.ordem > b.ordem) { return 1; }
           if (a.ordem < b.ordem) { return -1; }
           return 0;
         })

         this.Segmentos.forEach(element => {
         // console.log(this.SegmentosFiltro.filter(x=> x.nomeSegmento == element.nomeSegmento).length)
            if (this.SegmentosFiltro.filter(x=> x.nomeSegmento == element.nomeSegmento).length == 0){
            this.SegmentosFiltro.push({
                  segmentoInstNivel1Id: element.segmentoInstNivel1Id,
                  nomeSegmento: element.nomeSegmento,
                  visivel: element.visivel,
                  ordem:element.ordem
                });
                //console.log(this.SegmentosFiltro)
            }
          });
 
          this.SegmentosFiltro.sort(function (a, b) {
            if (a.ordem > b.ordem) { return 1; }
            if (a.ordem < b.ordem) { return -1; }
            return 0;
          })
 
       }); 

  },
  methods: {
  }
}
</script>

<style>
a {
  text-decoration: none !important;
}
</style>