<template>

  <div>

    <section class="ftco-section">
      <div class="container">
        <div class="row justify-content-center mb-5 pb-3">
          <div class="col-md-7 text-center heading-section ">
            <h2>{{ topoTitulo }}</h2>
          </div>
        </div>
        <div class="row d-md-flex">
          <div class="col-md-6  img about-image"
            :style="{ backgroundImage: 'url(' + imgTopo + ')', borderRadius: '15px' }">
          </div>
          <div class="col-md-6  p-md-5">
            <div class="row py-md-5">
              <div class="col-md-12 nav-link-wrap mb-5">
                <a href="" v-for="itemSobre in itens" :key="itemSobre.institucionalId" class="btn btn-primary py-3 px-4"
                  style="margin-right: 10px;" @click.prevent="CarregaDados(itemSobre)">{{ itemSobre.nome }}</a>
              </div>
              <div class="col-md-12 d-flex align-items-center">
                <div>
                  <h2 class="mb-4">{{ DadosTitulo }}</h2>
                  <p style="text-align:justify;" v-html="DadosTexto"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Numeros />

    <Depoimentos />

    <Parceiros />

  </div>
</template>

<script>
import Depoimentos from '../shared/Depoimentos.vue'
import Numeros from '../shared/Numeros.vue'
import Parceiros from '../shared/Parceiros.vue'

export default {
  components: {
    Depoimentos,
    Numeros,
    Parceiros,
  },
  data() {
    return {
      topoInternas: [],
      topoTitulo: '',
      imgTopo: '',
      itens: [],
      DadosTitulo: '',
      DadosTexto: '',
    }
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/empresaTopo")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.topoInternas = retornoAPI

        this.topoTitulo = this.topoInternas[0].nome
        if (this.topoInternas[0].institucionalImg != '') {
          this.imgTopo = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem
        }

      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/empresadescrições")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.itens = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })

        this.DadosTitulo = this.itens[0].nome;
        this.DadosTexto = this.itens[0].descricao1;
      });
  },
  methods: {
    CarregaDados(value) {
      this.DadosTitulo = value.nome;
      this.DadosTexto = value.descricao1;
    },
  }
}
</script>

