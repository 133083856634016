<template>
    <b-navbar toggleable="lg" class="container navbar navbar-expand-lg ftco_navbar" style="z-index: 99999;" >
        <b-navbar-brand>
          <router-link to="/" style="color:#6C6C6C;font-size: 13px;">
            <img src="/images/logo.jpg" class="img-fluid" style="width: 180px;" /> <span class="hidden-xs hidden-sm">Software e Consultoria para Gestão Competitiva</span>
          </router-link>
            <b-navbar-toggle target="nav-collapse"><i class="fa fa-bars" ></i></b-navbar-toggle>
        </b-navbar-brand>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="navbar-nav nav ml-auto">
              <b-nav-item exact class="nav-link" to="/"><span>Home</span></b-nav-item>
              <b-nav-item class="nav-link" to="/sobre"><span>Empresa</span></b-nav-item>
              <b-nav-item-dropdown id="my-nav-dropdown" text="Soluções" class="nav-link" toggle-class="nav-link" right>
                 <b-dropdown-item v-for="seg in SegmentosFiltro" :key="seg.segmentoInstNivel1Id" class="margemleft" @click="$redirect_reload('/solucoes/' + seg.segmentoInstNivel1Id + '/' + seg.nomeSegmento.replaceAll(' ','-').replaceAll('/',''))">{{seg.nomeSegmento}}</b-dropdown-item>
              </b-nav-item-dropdown>
              <!-- <b-nav-item class="nav-link" to="/blog"><span>Blog</span></b-nav-item> -->
              <b-nav-item class="nav-link" to="/contato"><span>Contato</span></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar> 
</template>

<script>
// import FiltraImoveis from './FiltraImoveis.vue'

export default {
  //props: ['topoFundo','topoCor'],
  components: {
    // FiltraImoveis
  },
  data() {
    return {
      socialFacebook: [],
      facebookLink: '',

      socialInstagram: [],
      instagramLink: '',

      socialYoutube: [],
      youtubeLink: '',

      socialTwitter: [],
      twitterLink: '',

      loginLink:[],

      Segmentos:[],
      SegmentosFiltro:[],
    }

  },
  created() {
    
     this.$http
       .get(this.$apiUrl + "/segmentoinstnivel1")
       .then((res) => res.json())
       .then((retornoAPI) => {
         this.Segmentos = retornoAPI.filter((x) => x.visivel)

        this.Segmentos.sort(function (a, b) {
           if (a.ordem > b.ordem) { return 1; }
           if (a.ordem < b.ordem) { return -1; }
           return 0;
         })

         this.Segmentos.forEach(element => {
         // console.log(this.SegmentosFiltro.filter(x=> x.nomeSegmento == element.nomeSegmento).length)
            if (this.SegmentosFiltro.filter(x=> x.nomeSegmento == element.nomeSegmento).length == 0){
            this.SegmentosFiltro.push({
                  segmentoInstNivel1Id: element.segmentoInstNivel1Id,
                  nomeSegmento: element.nomeSegmento,
                  visivel: element.visivel,
                  ordem:element.ordem
                });
                //console.log(this.SegmentosFiltro)
            }
          });
 
          this.SegmentosFiltro.sort(function (a, b) {
            if (a.ordem > b.ordem) { return 1; }
            if (a.ordem < b.ordem) { return -1; }
            return 0;
          })
 
       }); 

 }

}
</script>

<style>


</style>