<template>

  <div>
    <div v-if="slider!=''">
        <div class="container hero-wrap">
            <div class="row">
                <div class="col-md-4 slider-text">
                    <div class="text mt-5">
                      <span class="subheading">{{ slider[0].nome }}</span>
                      <h1 class="mb-3"><span>{{ slider[0].campoExtra1 }}</span></h1>
                      <p v-html="slider[0].descricao2"></p>
                    </div>
                </div>
                <div class="col-md-8 alturaimg" :style="{ backgroundImage: 'url(' + $apiUrlIndex + slider[0].institucionalImg[0].urlImagem + ')', backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}">
                  <!-- <figure id="container">
                      <span v-for="(item,index) in solucoes" :key="index">
                        <img v-if="slider[0].institucionalImg != ''" class="img-fluid" :src="$apiUrlIndex + item.institucionalImg[0].urlImagem" style="height: 60px;" /> 
                        <figcaption>{{ item.nome }}</figcaption>
                      </span>
                  </figure> -->
                </div>
            </div>
        </div>
    </div>
 <section v-if="chamadaHome!=''" class="ftco-section services-section bg-light">
      <div class="container">
        <div v-if="chamadaHome != '' && chamadaHome != null" class="row justify-content-center mb-5 pb-3">
          <div class="col-md-8 text-center heading-section ">
            <h2 class="">{{ chamadaHome[0].nome }}</h2>
            <p v-html="chamadaHome[0].descricao1"></p>
          </div>
        </div>
        <div class="row">
          <div v-for="(item,index) in solucoes" :key="index" class="col-md-6 d-flex align-self-stretch ">
            
            <div class="media block-6 services d-flex align-items-center">
              <div v-if="index%2 == 0" class="icon d-flex align-items-center justify-content-center order-md-last">
                <a href="#" @click="$redirect_reload(item.referencia)">
                <img v-if="slider[0].institucionalImg != ''" class="one-third align-self-end order-md-last img-fluid"
                  :src="$apiUrlIndex + item.institucionalImg[0].urlImagem" alt="">
                </a>
              </div>
              <div v-else class="icon d-flex align-items-center justify-content-center">
                <a href="#" @click="$redirect_reload(item.referencia)">
                <img v-if="slider[0].institucionalImg != ''" class="one-third align-self-end order-md-last img-fluid"
                  :src="$apiUrlIndex + item.institucionalImg[0].urlImagem" alt="">
                </a>
              </div>
              <div v-if="index%2 == 0" class="media-body pl-4 pr-3 text-md-right">
                <a href="#" @click="$redirect_reload(item.referencia)"><h3 class="heading">{{ item.nome }}</h3></a>
                <p class="mb-0" v-html="item.descricao2"></p>
              </div> 
               <div v-else class="media-body pl-4">
                <a href="#" @click="$redirect_reload(item.referencia)"><h3 class="heading">{{ item.nome }}</h3></a>
                <p class="mb-0" v-html="item.descricao2"></p>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section v-if="InfraDesc!=''" class="ftco-section ftco-no-pt ftc-no-pb">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 py-5">
            <div class="heading-section  mt-5">
              <h2 class="mb-4">{{ InfraDesc[0].nome }}</h2>
              <p v-html="InfraDesc[0].descricao1"></p>
            </div>
            <img v-if="InfraDesc[0].institucionalImg!=''" :src="$apiUrlIndex + InfraDesc[0].institucionalImg[0].urlImagem" class="img-fluid" alt=""
              style="width: 90%;">
          </div>
          <div class="col-lg-6 py-5">
            <div class="row">
              <div v-for="itemInfra in InfraItens" :key="itemInfra.institucionalId" class="col-md-6 ">
                <div class="media block-6 services border text-center" style="min-height: 500px !important;">
                  <div class="icon d-flex align-items-center justify-content-center">
                    <img v-if="itemInfra.institucionalImg != ''" class="one-third align-self-end order-md-last img-fluid"
                      :src="$apiUrlIndex + itemInfra.institucionalImg[0].urlImagem" alt="">
                  </div>
                  <div class="mt-3 media-body media-body-2">
                    <h3 class="heading">{{ itemInfra.nome }}</h3>
                    <p v-html="itemInfra.descricao1"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Numeros />

    <Depoimentos />

    <section class="ftco-section bg-light" style="display:none;">
      <div class="container">
        <div class="row justify-content-center mb-5 pb-3">
          <div class="col-md-7 text-center heading-section ">
            <h2>Blog</h2>
          </div>
        </div>
        <div class="row">
          <div v-for="blog in Blogs.slice(0, 3)" :key="blog.institucionalId" class="col-md-4 ">
            <div class="blog-entry">
              <router-link v-if="blog.institucionalImg!=''" :to="/blogDetalhe/ + blog.institucionalId + '/' + blog.nome.replaceAll(' ','-').replaceAll('/','')" class="block-20"
                :style="{ backgroundImage: 'url(' + $apiUrlIndex + blog.institucionalImg[0].urlImagem + ')' }">
              </router-link>
              <div class="text d-flex py-4">
                <div class="desc pl-3">
                  <div>
                    <router-link :to="/blogDetalhe/ + blog.institucionalId + '/' + blog.nome.replaceAll(' ','-').replaceAll('/','')">
                      {{ currentDateTime(blog.data1) }}
                    </router-link>
                  </div>
                  <h3 class="heading">
                    <router-link :to="/blogDetalhe/ + blog.institucionalId + '/' + blog.nome.replaceAll(' ','-').replaceAll('/','')">
                      {{ blog.nome }}
                    </router-link>
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Parceiros />
   
  </div>
</template>

<script>
import Depoimentos from '../shared/Depoimentos.vue'
//import { Carousel, Slide } from 'vue-carousel';
import Numeros from '../shared/Numeros.vue'
import Parceiros from '../shared/Parceiros.vue'
import moment from 'moment'


export default {
  components: {
    Depoimentos,
    Numeros,
    Parceiros,
    //Carousel,
    //Slide
  },
  data() {
    return {
      slider: [],
      chamadaHome: [],
      solucoes: [],
      itenshome: [],
      InfraDesc: [],
      InfraItens: [],
      Blogs: [],
    }
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/Banner%20Topo")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.slider = retornoAPI

      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/chamadahome")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.chamadaHome = retornoAPI

      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/soluçõeshome")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.solucoes = retornoAPI.filter((x) => x.visivel)

        this.solucoes.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/itenshome")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.itenshome = retornoAPI.filter((x) => x.visivel)

        this.itenshome.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/infraestruturadescrição")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.InfraDesc = retornoAPI
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/infraestruturaitens")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.InfraItens = retornoAPI.filter((x) => x.visivel)

        this.InfraItens.sort(function (a, b) {
          if (a.ordem > b.ordem) { return 1; }
          if (a.ordem < b.ordem) { return -1; }
          return 0;
        })
      });

    this.$http
      .get(this.$apiUrl + "/institucional/telas/notícias")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.Blogs = retornoAPI.filter((x) => x.visivel)

        this.Blogs.sort(function (a, b) {
          if (a.data1 > b.data1) { return 1; }
          if (a.data1 < b.data1) { return -1; }
          return 0;
        }).reverse();
      });
  },
  methods: {
    currentDateTime(vdate) {
      return moment(vdate).format('DD/MM/YYYY')
    },
  }
}

</script>

<style>
/* .container {
  position: relative;
}

#image {
  position: absolute;
}

#texto {
  position: absolute;
  font-size: 32px;
  left: 250px;
  top: 135px;
} */

#container {
  display: inline-block;
  position: relative;
}

/* #container figcaption span {
  position: absolute;
  top: 145px;
  right: 20px;
  font-size: 40px;
  color: black;
  text-shadow: 0px 0px 5px black;
} */
</style>

