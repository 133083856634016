import Vue from 'vue'
import App from './App.vue'
import VueResource from 'vue-resource'
import router from './router/routes'
import { BootstrapVue} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueIframe from 'vue-iframes'
import Vue2Filters from 'vue2-filters'
import vSelect from 'vue-select'
import Lingallery from 'lingallery';
import money from "v-money";
import swal from "sweetalert";
import VueCarousel from 'vue-carousel';
Vue.use(VueCarousel);
import VueAgile from 'vue-agile'
import VueViewer from 'v-viewer'
Vue.use(VueViewer)

Vue.use(VueAgile)

import 'vue-select/dist/vue-select.css';
import VueSimpleAccordion from 'vue-simple-accordion';
import 'vue-simple-accordion/dist/vue-simple-accordion.css';


Vue.use(VueSimpleAccordion, {});

Vue.component('lingallery', Lingallery);
Vue.component('v-select', vSelect)
Vue.use(VueResource)
Vue.use(BootstrapVue)
Vue.use(require('vue-moment'))
Vue.use(VueIframe)
Vue.use(Vue2Filters)
Vue.use(money, { precision: 2 });

Vue.config.productionTip = false

Vue.prototype.$apiUrl = "https://controle.status.inf.br/api"
Vue.prototype.$apiUrlIndex = "https://controle.status.inf.br/"
Vue.prototype.$redirect_reload = function(path) {
  this.$router.push(path);
  this.$router.go(this.$router.currentRoute)
}
Vue.prototype.$redirect = function(path) {
  this.$router.push(path)
}

Vue.prototype.$mensagem_sucesso = function (mensagem) {
  swal(" ", mensagem, "success");
};
Vue.prototype.$mensagem_normal = function (mensagem) {
  swal(mensagem);
};
Vue.prototype.$mensagem_erro = function (mensagem) {
  swal(" ", mensagem, "error");
};

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
