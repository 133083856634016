<template>

  <div>
    <!-- :style="{ backgroundImage: 'url(' + TopoBanner + ')', minHeight: '100%', backgroundSize: 'contain',backgroundRepeat: 'no-repeat'}" -->
    <div>
      <img :src="TopoBanner" style="width:100%;" />
    </div>

    <section v-if="SolucaoTopo!=''" class="ftco-no-pt ftc-no-pb">
      <div class="container">
        <div class="row">
          <div class="col-lg-6 py-5">
            <div class="heading-section ">
              <h2 class="mb-4">{{ SolucaoTopo[0].nome }}</h2>
              <p style="text-align: justify;" v-html="SolucaoTopo[0].descricao1"></p>
            </div>
            <div style="text-align: center;">
              <img :src="$apiUrlIndex + SolucaoTopo[0].institucionalImg[0].urlImagem" class="img-fluid align-middle"
                alt="" style="width: 420px;">
            </div>
          </div>
          <div class="col-lg-6 py-5">
            <div class="row">
              <div v-for="itens in SolucaoItens" :key="itens.institucionalId" class="col-md-6 ">
                <div class="media block-6 services border text-center" style="min-height: 350px;">
                  <div class="icon d-flex align-items-center justify-content-center">
                    <img v-if="itens.institucionalImg!=''" :src="$apiUrlIndex + itens.institucionalImg[0].urlImagem"
                      class="img-fluid" alt="">
                  </div>
                  <div class="mt-3 media-body media-body-2">
                    <h3 class="heading">{{itens.nome}}</h3>
                    <p v-html="itens.descricao1"></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-7 text-center heading-section ">
            <h2 class="mb-4">Destaques da Solução</h2>
          </div>
        </div>
        <div class="row mb-5 mt-5">
          <div class="col-md-12 nav-link-wrap pb-sm-1 mb-3">
            <a v-for="itemSobre in Caracteristica" :key="itemSobre.institucionalId" class="btn btn-primary py-3 px-3"
              style="margin-right: 10px;margin-top: 10px;" @click.prevent="CarregaDados(itemSobre)">{{ itemSobre.nome
              }}</a>
          </div>
          <div class="col-md-5">
            <img :src="DadosImg" class="img-fluid" alt="">
          </div>
          <div class="col-md-7" style="padding-top: 20px;">
            <h2 class="mb-4">{{ DadosTitulo }}</h2>
            <p v-html="DadosTexto"></p>
          </div>
        </div>
      </div>
    </section>

    <section class="ftco-section bg-light">
      <div class="container">
        <div class="row justify-content-center mb-5">
          <div class="col-md-7 text-center heading-section ">
            <h2 class="mb-4">{{PorqueFundoTitulo}}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 ">
            <div id="accordion">
              <div class="row">
                <div class="col-md-6">
                  <img v-if="PorqueFundoImg!=''" :src="PorqueFundoImg" class="img-fluid" alt="">
                </div>
                <div class="col-md-6">
                  <vsa-list>
                    <vsa-item v-for="faq in ListaFaq" :key="faq.institucionalId">
                      <vsa-heading>
                        {{ faq.nome }}
                      </vsa-heading>
                      <vsa-content>
                        <p v-html="faq.descricao1"></p>
                      </vsa-content>
                    </vsa-item>
                  </vsa-list>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section v-if="Galeria!=''" class="ftco-section">
      <div class="container">
        <div class="row align-items-center d-flex">
          <h4 class="mb-4">Conheça o Produto {{ Galeria[0].segmentoInstNivel1.nomeSegmento }}</h4>
          <div class="row gallery-item">
            <div class="images" v-viewer>
              <img v-for="src in Galeria[0].institucionalImg" :src="$apiUrlIndex + src.urlImagem"
                :key="src.institucionalImgId" style="height: 100px;padding-right: 5px;">
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="ftco-section" style="background-color: #5423c6 !important;">
      <div class="container">
        <div class="row align-items-center d-flex">
          <div class="col-md-9">
            <div class="heading-section heading-section-white ">
              <span class="subheading">{{CallNomeMenor}}</span>
              <h2 style="font-size: 30px;">{{CallNomeMaior}}</h2>
            </div>
          </div>
          <div class="col-md-3 ">
            <p class="mb-0"><a :href="CallLinkBtn" class="btn btn-primary py-3 px-4"
                style=" background: #6927ff;border: 1px solid #6927ff;color: #fff;border-radius:25px;">{{CallTextoBtn}}</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import 'viewerjs/dist/viewer.css'

export default {
  components: {
  },
  data() {
    return {
      ValueId: this.$route.params.id,
      TopoBanner: '',
      SolucaoTopo:[],
      SolucaoItens:[],
      Caracteristica:[],
      DadosTitulo: '',
      DadosTexto: '',
      DadosImg:'',
      PorqueFundo:[],
      PorqueFundoTitulo:'',
      PorqueFundoImg:'',
      CallAction:[],
      CallNomeMenor:'',
      CallNomeMaior:'',
      CallTextoBtn:'',
      CallLinkBtn:'',
      ListaFaq:[],
      BannerTopo:[],
      Galeria:[],
    }
  },
  created() {
    this.$http
        .get(this.$apiUrl + "/institucional/telas/soluçõestopo")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.SolucaoTopo = retornoAPI.filter((x) => x.visivel &&  x.segmentoInstNivel1.segmentoInstNivel1Id == this.ValueId)
        
              this.$http
                  .get(this.$apiUrl + "/institucional/telas/soluçõesitens")
                  .then((res) => res.json())
                  .then((retornoAPI) => {
                    this.SolucaoItens = retornoAPI.filter((x) => x.visivel &&  x.segmentoInstNivel1.nomeSegmento == this.SolucaoTopo[0].segmentoInstNivel1.nomeSegmento)
                  }); 

              this.$http
                  .get(this.$apiUrl + "/institucional/telas/caracteristicas")
                  .then((res) => res.json())
                  .then((retornoAPI) => {
                    this.Caracteristica = retornoAPI.filter((x) => x.visivel &&  x.segmentoInstNivel1.nomeSegmento == this.SolucaoTopo[0].segmentoInstNivel1.nomeSegmento).sort(function (a, b) {
                        if (a.ordem > b.ordem) { return 1; }
                        if (a.ordem < b.ordem) { return -1; }
                        return 0;
                      })
                  
                    this.DadosTitulo = this.Caracteristica[0].nome;
                    this.DadosTexto = this.Caracteristica[0].descricao1;
                    if (this.Caracteristica[0].institucionalImg!=''){
                      this.DadosImg = this.$apiUrlIndex + this.Caracteristica[0].institucionalImg[0].urlImagem;
                    }
                    
                  }); 

                  this.$http
                  .get(this.$apiUrl + "/institucional/telas/porque")
                  .then((res) => res.json())
                  .then((retornoAPI) => {
                    this.ListaFaq = retornoAPI.filter((x) => x.visivel &&  x.segmentoInstNivel1.nomeSegmento == this.SolucaoTopo[0].segmentoInstNivel1.nomeSegmento)
                      this.ListaFaq.sort(function (a, b) {
                        if (a.ordem > b.ordem) { return 1; }
                        if (a.ordem < b.ordem) { return -1; }
                        return 0;
                      })
                  }); 

                  this.$http
                  .get(this.$apiUrl + "/institucional/telas/bannertoposoluções")
                  .then((res) => res.json())
                  .then((retornoAPI) => {
                    this.BannerTopo = retornoAPI.filter((x) => x.visivel &&  x.segmentoInstNivel1.nomeSegmento == this.SolucaoTopo[0].segmentoInstNivel1.nomeSegmento)
                  
                      if (this.BannerTopo[0].institucionalImg!=''){
                        this.TopoBanner = this.$apiUrlIndex + this.BannerTopo[0].institucionalImg[0].urlImagem;
                      }
                  }); 

                   this.$http
                  .get(this.$apiUrl + "/institucional/telas/imagensproduto")
                  .then((res) => res.json())
                  .then((retornoAPI) => {
                    this.Galeria = retornoAPI.filter((x) => x.segmentoInstNivel1.nomeSegmento == this.SolucaoTopo[0].segmentoInstNivel1.nomeSegmento)
                    console.log(this.Galeria)
                 
                 }); 

                  
        });

      this.$http
        .get(this.$apiUrl + "/institucional/telas/porquefundo")
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.PorqueFundo = retornoAPI;
          if (this.PorqueFundo!=''){
            this.PorqueFundoTitulo = this.PorqueFundo[0].nome;
            if (this.PorqueFundo[0].institucionalImg!=''){
                this.PorqueFundoImg = this.$apiUrlIndex + this.PorqueFundo[0].institucionalImg[0].urlImagem;
            }
            
          }
      }); 

      this.$http
      .get(this.$apiUrl + "/institucional/telas/callactionsolucoes")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.CallAction = retornoAPI;
        if (this.CallAction!=''){
          this.CallNomeMenor = this.CallAction[0].nome;
          this.CallNomeMaior = this.CallAction[0].campoExtra1;
          this.CallTextoBtn = this.CallAction[0].textoBtn1;
          this.CallLinkBtn = this.CallAction[0].linkBtn1;                  
        }
    }); 

            
  },      
  methods: {
   CarregaDados(value) {
      this.DadosTitulo = value.nome;
      this.DadosTexto = value.descricao1;
      this.DadosImg = this.$apiUrlIndex + value.institucionalImg[0].urlImagem;
    },
  }
}

</script>

<style>
.vsa-item__trigger:focus, .vsa-item__trigger:hover {
    outline: none;
    background-color: #5423c6 !important;
    color: var(--vsa-bg-color);
}
</style>
