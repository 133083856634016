<template>
  <div id="app">

    <div v-html="codigo_SEO"></div>

    <Header /> 

    <transition name="fadeIn">
      <router-view></router-view>
    </transition>

    <Footer />

  </div>
</template>

<script>

  import Header from './components/site/shared/Header.vue'
  import Footer from './components/site/shared/Footer.vue'

  export default {
    name: 'App',
    components: {
       Header,
      Footer
    },
    data() {
      return {

        seobody: [],
        codigo_SEO: '',
        seoHead:[],
        codigoHead: '',
        seoAnalitics:[],
        codigoAnalitics: '',
        metaHome: [],
        codigoHome: ''
        
      }
    },
    created() {
      
      this.$http
      .get(this.$apiUrl + "/seobody/")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.seobody = retornoAPI
        if (this.seobody!= '' && this.seobody!= null){
           this.codigo_SEO = this.seobody[0].codigo.replaceAll('<p>','').replaceAll('</p>','').trim('"')
        }
      });


    this.$http
      .get(this.$apiUrl + "/seohead/")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.seoHead = retornoAPI;
       if (this.seoHead!='' && this.seoHead!=null){
             this.codigoHead = this.seoHead[0].codigo.trim('"');
        }
      });


      this.$http
      .get(this.$apiUrl + "/seoanalitycs/")
      .then((res) => res.json())
      .then((idi) => {
        this.seoAnalitics = idi;
        if (this.seoAnalitics!='' && this.seoAnalitics!=null){
            this.codigoAnalitics = this.seoAnalitics[0].codigo.trim('"');
        }
      });


      this.$http
      .get(this.$apiUrl + "/Seometahome/")
      .then((res) => res.json())
      .then((idi) => {
        this.metaHome = idi;
        if (this.metaHome!='' && this.metaHome!=null){
          this.codigoHome = this.metaHome[0].codigo.trim('"');
        }
        document.head.innerHTML += this.codigoHead + this.codigoAnalitics + this.codigoHome;
      });
    },
     methods: {
  }
}
</script>

<style>
 @import '@/css/ionicons.min.css';
@import '@/css/animate.css';
@import '@/css/owl.theme.default.min.css';
@import '@/css/magnific-popup.css';
@import '@/css/ionicons.min.css';
@import '@/css/bootstrap-datepicker.css';
@import '@/css/jquery.timepicker.css';
@import '@/css/flaticon.css';
@import '@/css/icomoon.css';
@import '@/css/style.css'; 

  /* ---------  */
  .ql-align-justify {
  text-align: justify !important;
  }
  .ql-align-right{
  text-align: right !important;
  }

  .ql-align-center{
  text-align: center !important;
  }


  /*---------- Animations */
  .fadeIn-enter {
    opacity: 0;
  }
  .fadeIn-enter-active {
    transition: opacity .8s;
  }
  .fadeIn-enter-to {
  opacity: 1;
  }

@media (max-width:768px) {
    .hidden-xs {
        display: none !important;
    }

    .alturaimg {
      height: 280px;
    }

}

@media (min-width:768px) and (max-width:991px) {
    .hidden-sm {
        display: none !important;
    }
    .alturaimg {
      height: 380px;
    }

  }


@media (min-width:992px) and (max-width:1199px) {
    .hidden-md {
        display: none !important;
    }

    .alturatopo {
      margin-top: -70px;
}
.alturaimg {
      height: 420px;
    }

}

@media (min-width:1200px) {
    .hidden-lg {
        display: none !important;
    }

   .hero-wrap {
    width: 100%;
    height: 100%;
    z-index: 0;
    padding-top: 0px !important;
}

.alturatopo {
      margin-top: -100px;
}

.alturaimg {
      height: 500px;
    }

}
</style>
