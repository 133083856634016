<template>

  <div>
    <section class="ftco-section contact-section ftco-degree-bg">
      <div class="container">
        <div class="row justify-content-center mb-5 pb-3">
          <div class="col-md-7 text-center heading-section ">
            <h2>{{topoTitulo}}</h2>
          </div>
        </div>
        <div class="row d-flex mb-5 contact-info">
          <div class="w-100"></div>
          <div class="col-md-3">
            <p v-if="contatoEndereco != '' && contatoEndereco != null">
              <span style="font-weight: 500;">Endereço</span><br /><a>
                <p v-html="contatoEndereco"></p>
              </a>
            </p>
          </div>
          <div class="col-md-3">
            <div v-if="contatoTelefones != '' && contatoTelefones != null">
              <span style="font-weight: 500;">Telefone</span><br />
               <p v-html="contatoTelefones"></p>
            </div>
          </div>
           <div v-if="contatoEmails1 != '' && contatoEmails1 != null" class="col-md-3">
            <p><span>Comercial<br/></span> <a href="">{{contatoEmails1}}</a></p>
          </div>
          <div v-if="contatoEmails2 != '' && contatoEmails2 != null" class="col-md-3">
            <p><span>Suporte</span><br/> <a href="#">{{contatoEmails2}}</a></p>
          </div>
        </div>
        <div class="row block-9">
          <div class="col-md-6 pr-md-5">
            <form>
              <div class="form-group">
                <input v-model="nome" type="text" class="form-control" placeholder="Nome">
              </div>
              <div class="form-group">
                <input v-model="email" type="text" class="form-control" placeholder="E-mail">
              </div>
              <div class="form-group">
                <input v-model="telefone" type="text" class="form-control" placeholder="Telefone">
              </div>
              <div class="form-group">
                <textarea v-model="mensagem" name="" id="" cols="30" rows="7" class="form-control"
                  placeholder="Mensagem"></textarea>
              </div>
              <div class="form-group">
                <button type="button" class="btn btn-primary py-3 px-5" @click="enviarDadosFormulario"
                  style="border-radius:25px;">Enviar</button>
              </div>
            </form>
          </div>
          <div class="col-md-6">
           <iframe 
                  :src="'https://www.google.com/maps/embed/v1/place?key=AIzaSyDmXp4J53f9Wt7iHX7dzIn7rHG7yL-EXXo&q=' + contatoMapa + ' '" 
                  width="100%" 
                  height="450px" 
                  frameborder="0" 
                  style="border:0;" 
                  allowfullscreen="" 
                  aria-hidden="false" 
                  tabindex="0">
                </iframe>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {
  },
  data() {
    return {
      contato: [],
      topoTitulo: '',
      textoTitulo: '',
      contatoEndereco: '',
      contatoTelefones: '',
      contatoEmails1: '',
       contatoEmails2: '',
      contatoMapa: '',
      contatoImg: '',
      contatoEmail: '',
      // Objeto Contato  
      nome: '',
      email: '',
      telefone: '',
      mensagem: '',
      Contato: {
        NomeEmpresa: "Status Informatica ",
        Assunto: "Contato",
        Destinatario: "",
        EmailResposta: "",
        TextoEmail: ""
      }

    }
  },
  created() {

    this.$http
      .get(this.$apiUrl + "/institucional/telas/infocontato")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.contato = retornoAPI
        if (this.contato != '') {
          this.topoTitulo = this.contato[0].nome
          this.contatoEmail = this.contato[0].referencia
          this.contatoEndereco = this.contato[0].descricao1
          this.contatoTelefones = this.contato[0].descricao2
          this.contatoEmails1 =  this.contato[0].campoExtra1
          this.contatoEmails2 =  this.contato[0].campoExtra2
          this.contatoMapa = this.contato[0].descricao3;
        }
      });

  },
  methods: {
    enviarDadosFormulario() {

      //Email que irá receber os dados do formulário preenchido (empresa site)
      this.Contato.Destinatario = this.contatoEmail

      //Email de quem preencheu formulário e enviou email (usuário/cliente)
      this.Contato.EmailResposta = this.email

      // Dados que aparecerão no corpo do email
      this.Contato.TextoEmail =
        "<br /><br />" + 
        "<div style='font-size:18px;'><strong>Nome:</strong> " + this.nome +
        " <br /> <strong>Telefone:</strong> " + this.telefone +
        " <br /> <strong>Email:</strong> " + this.email +
        " <br /> <strong>Descrição:</strong> " + this.mensagem + "</div>"

      //validar vazios
      if (this.nome == '' || this.telefone == '' || this.email == '' || this.mensagem == '') {
        this.$mensagem_normal("Preencha todos os campos");

      } else {

        //SE NAO TIVER ANEXO:
        // console.log(this.Contato)

        this.$http.post(this.$apiUrl + "/contato/enviamail", this.Contato)
          .then(response => {
            if (response.ok)
              alert("Enviado com sucesso!")
            this.nome = ''
            this.telefone = ''
            this.email = ''
            this.mensagem = ''
            this.Contato.Assunto = ""
          }, error => {
            alert(error.bodyText)
            this.nome = ''
            this.telefone = ''
            this.email = ''
            this.mensagem = ''
            this.Contato.Assunto = ""
          });

      }

    }
  }

}

</script>

