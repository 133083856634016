<template>
  
  
  <section v-if="CadDepoimentos!='' && CadDepoimentos!=null" class="ftco-section testimony-section">
    <div class="container">
      <div v-if="DepoimentosFundo!=''" class="row justify-content-center pb-3">
        <div class="col-md-7 text-center heading-section ">
          <span class="subheading">{{ DepoimentosFundo[0].nome}}</span>
          <h2 class="mb-4">{{ DepoimentosFundo[0].campoExtra1}}</h2>
          <p v-html="DepoimentosFundo[0].descricao1"></p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="carousel-testimony">
            <carousel
              id="carousel-1"
                      autoplay
                      :autoplayTimeout=4000
                       autoplayHoverPause
                      :paginationEnabled="false"
                      :paginationSize=0
                      :loop="true"
              :perPageCustom="[[340, 1],[768, 1], [1024,3], [1600,3]]"
              v-if="CadDepoimentos!='' && CadDepoimentos!=null"
            >

              <!-- 1 -->
              <slide v-for="depoimentos in CadDepoimentos" :key="depoimentos.institucionalId"
                img-blank img-alt="Blank image" style="padding-right: 10px !important;">
              <div class="item">
                <div class="testimony-wrap p-4 text-center">
                  <div v-if="depoimentos.institucionalImg!='' && depoimentos.institucionalImg!=null" class="user-img mb-4" :style="{ backgroundImage: 'url(' + $apiUrlIndex + depoimentos.institucionalImg[0].urlImagem + ')' }">
                    <span class="quote d-flex align-items-center justify-content-center">
                      <i class="icon-quote-left"></i>
                    </span>
                  </div>
                  <div class="text">
                    <p class="mb-4" v-html="depoimentos.resumo1"></p>
                    <p class="name">{{ depoimentos.nome }}</p>
                    <span class="position">{{ depoimentos.campoExtra1 }}</span>
                  </div>
                </div>
              </div>
              </slide>

            </carousel>

          </div>
        </div>
      </div>
    </div>
  </section>

</template>


<script>
 import { Carousel, Slide } from 'vue-carousel';
export default {
  components: {
            Carousel,
            Slide,
        },
  data() {
    return {
      DepoimentosFundo:[],
      CadDepoimentos:[],
    }
  },
  created() {

     this.$http
    .get(this.$apiUrl + "/institucional/telas/depoimentostítulo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.DepoimentosFundo = retornoAPI
    });

    this.$http
    .get(this.$apiUrl + "/institucional/telas/depoimentos")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.CadDepoimentos = retornoAPI.filter((x) => x.visivel)

      this.CadDepoimentos.sort(function (a, b) {
        if (a.ordem > b.ordem) { return 1; }       
        if (a.ordem < b.ordem) { return -1; }        
        return 0;
      })
    });

  }
}
</script>

<style>
/* .VueCarousel-wrapper {
    width: 100%;
    position: relative;
    overflow: visible !important;
} */
</style>