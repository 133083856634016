<template>

  <div>
    <section class="ftco-section">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ">
            <h2 class="mb-3">{{ blogTitulo }}</h2>
            <img :src="blogImg" class="img-fluid" />
            <p v-html="blogDescricao" style="padding-top: 30px;"></p>
          </div>
          <div class="col-md-4 sidebar ">
            <div class="sidebar-box ">
              <h3 class="mb-3">Recentes</h3>
              <div v-for="noticia in blog" :key="noticia.id" class="block-21 mb-4 d-flex ">
                <a href="#" @click.prevent="carregaNoticia(noticia.institucionalId)" class="blog-img mr-4">
                  <img :src="$apiUrlIndex + noticia.institucionalImg[0].urlImagem" style="height: 60px;" />
                </a>
                <div class="text">
                  <h3 class="heading"><a href="#" @click.prevent="carregaNoticia(noticia.institucionalId)">{{
                      noticia.nome
                  }}</a></h3>
                  <div class="meta">
                    <div><a href="#" @click.prevent="carregaNoticia(noticia.institucionalId)"><span
                          class="icon-calendar"></span> {{ currentDateTime(noticia.data1) }}</a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  components: {
  },
  data() {
    return {
      blog: [],

      noticia: {},
      blogImg: '',
      blogTitulo: '',
      blogDescricao: '',
    }
  },
  created() {

    // Blog
    this.$http
      .get(this.$apiUrl + "/institucional/telas/notícias")
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.blog = retornoAPI.filter((x) => x.visivel)

      });

    this.carregaNoticia(this.$route.params.id)

  },
  methods: {
    currentDateTime(vdate) {
      return moment(vdate).format('DD/MM/YYYY')
    },
    carregaNoticia(idNoticia) {
      this.$http
        .get(this.$apiUrl + "/institucional/" + idNoticia)
        .then((res) => res.json())
        .then((retornoAPI) => {
          this.noticia = retornoAPI

          this.blogTitulo = this.noticia.nome
          this.blogDescricao = this.noticia.descricao1
          this.blogImg = this.$apiUrlIndex + this.noticia.institucionalImg[0].urlImagem

          if (this.noticia.institucionalImg[0] != null) {
            this.blogImg = this.$apiUrlIndex + this.noticia.institucionalImg[0].urlImagem;
          }
          else {
            this.blogImg = '';
          }

        });
    },
  }
}

</script>

<style>
</style>